import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconRooms = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_25_441)">
        <path
          d="M39.5833 12.5001H27.0833C25.1809 12.507 23.3168 13.0363 21.6945 14.03C20.0722 15.0237 18.7539 16.4439 17.8833 18.1355C16.6342 17.1871 15.11 16.6715 13.5417 16.6667C11.6078 16.6667 9.75313 17.435 8.38568 18.8024C7.01823 20.1699 6.25 22.0245 6.25 23.9584C6.2561 25.0417 6.50603 26.1098 6.98125 27.0834H4.16667V6.25007C4.16667 5.69753 3.94717 5.16763 3.55647 4.77693C3.16577 4.38623 2.63587 4.16673 2.08333 4.16673C1.5308 4.16673 1.0009 4.38623 0.610194 4.77693C0.219493 5.16763 0 5.69753 0 6.25007L0 43.7501C0 44.3026 0.219493 44.8325 0.610194 45.2232C1.0009 45.6139 1.5308 45.8334 2.08333 45.8334C2.63587 45.8334 3.16577 45.6139 3.55647 45.2232C3.94717 44.8325 4.16667 44.3026 4.16667 43.7501V39.5834H45.8333V43.7501C45.8333 44.3026 46.0528 44.8325 46.4435 45.2232C46.8342 45.6139 47.3641 45.8334 47.9167 45.8334C48.4692 45.8334 48.9991 45.6139 49.3898 45.2232C49.7805 44.8325 50 44.3026 50 43.7501V22.9167C49.9967 20.1551 48.8982 17.5075 46.9454 15.5547C44.9926 13.6019 42.345 12.5034 39.5833 12.5001ZM20.8333 22.9167C20.8333 21.2591 21.4918 19.6694 22.6639 18.4973C23.836 17.3252 25.4257 16.6667 27.0833 16.6667H39.5833C41.2409 16.6667 42.8306 17.3252 44.0028 18.4973C45.1749 19.6694 45.8333 21.2591 45.8333 22.9167V27.0834H20.8333V22.9167ZM10.4167 23.9584C10.4167 23.3403 10.5999 22.7361 10.9433 22.2222C11.2867 21.7083 11.7748 21.3078 12.3458 21.0713C12.9168 20.8348 13.5451 20.7729 14.1513 20.8934C14.7575 21.014 15.3143 21.3117 15.7514 21.7487C16.1884 22.1857 16.486 22.7426 16.6066 23.3487C16.7272 23.9549 16.6653 24.5833 16.4288 25.1543C16.1923 25.7253 15.7917 26.2134 15.2778 26.5567C14.7639 26.9001 14.1597 27.0834 13.5417 27.0834C12.7129 27.0834 11.918 26.7542 11.332 26.1681C10.7459 25.5821 10.4167 24.7872 10.4167 23.9584ZM4.16667 35.4167V31.2501H45.8333V35.4167H4.16667Z"
          fill="#3e5ba6"
        />
      </g>
      <defs>
        <clipPath id="clip0_25_441">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconRooms.defaultProps = {
  className: null,
  rootClassName: null,
};

IconRooms.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconRooms;
