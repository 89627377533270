import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, Icons } from '../../components';

import css from './ListingPage.module.css';
import IconSelfCheckin from '../../components/IconSelfCheckin/IconSelfCheckin';
import IconRooms from '../../components/IconRooms/IconRooms';
import IconCalender from '../../components/IconCalender/IconCalender';
import moment from 'moment';
import { listingTypeOptions } from '../../config/configListing';
import { amenitiesIconsData } from '../../helpers/amenitiesHelpers';
import { isArrayLength } from '../../util/genericHelpers';

const SectionDetailsMaybe = props => {
  const { publicData, metadata = {}, listingConfig, intl, currentListing } = props;
  const { listingFields } = listingConfig || {};

  if (!publicData || !listingConfig) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
        ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
        : schemaType === 'long'
        ? filteredConfigs.concat({ key, value, label })
        : filteredConfigs;
    }
    return filteredConfigs;
  };

  const typeOption = listingTypeOptions.find(o => o.option === publicData?.type);

  const amenitiesData = amenitiesIconsData
    .map(e => {
      const index =
        isArrayLength(publicData?.amenities) &&
        publicData?.amenities?.findIndex(a => a === e?.value);
      return index > -1 ? e : null;
    })
    .filter(Boolean);

  const existingListingFields = listingFields.reduce(pickListingFields, []);
  return existingListingFields.length > 0 ? (
    <>
      <div className={css.workSpaceInfo}>
        <div className={css.workSpaceBlock}>
          <span>
            <IconRooms />
          </span>
          <h2>{typeOption?.label}</h2>
          <p>{typeOption?.label}</p>
        </div>{' '}
        <div className={css.workSpaceBlock}>
          <span>
            <IconSelfCheckin />
          </span>
          <h2>House Rules</h2>
          <p> Check-in after {publicData?.checkin}</p>
          <p className={css.checkout}>Checkout before {publicData?.checkout}</p>
        </div>
        <div className={css.workSpaceBlock}>
          <span>
            <IconCalender />
          </span>
          <h2>
            Free cancellation before{' '}
            {moment()
              .add(1, 'month')
              .format('Do MMM')}
          </h2>
          <p>
            {' '}
            Guests get a 100% refund if they cancel at least 30 days before their check-in date.
          </p>
        </div>
      </div>
      {/* <div className={css.sectionDetails}>
        <Heading as="h2" rootClassName={css.sectionHeading}>
          <FormattedMessage id="ListingPage.detailsTitle" />
        </Heading>
        <div className={css.experienceSec}>
          {existingListingFields
            .filter(e => !['type', 'category'].includes(e.key))
            .map(detail => {
              const renderIcon = () => {
                switch (detail.value) {
                  case 'No':
                    return <Icons name="notAllowedIcon" />;
                  case 'Yes':
                    return <Icons name="allowedIcon" />;
                  default:
                    return <></>;
                }
              };

              return (
                <div className={css.experienceBlock} key={detail.key}>
                  <div className={css.experienceIcon}>{renderIcon()}</div>
                  <div className={css.experienceInfo}>
                    <h2>{detail.label}</h2>
                    <p>{detail.value}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div> */}
    </>
  ) : null;
};

export default SectionDetailsMaybe;
