import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2, Reviews, Avatar, UserDisplayName } from '../../components';

import css from './ListingPage.module.css';
import IconStar from '../../components/IconStar/IconStar';
import IconStarFilled from '../../components/IconStarFilled/IconStarFilled';
import { appendAverageReviews, isArrayLength } from '../../util/genericHelpers';

const SectionReviews = props => {
  const { reviews, fetchReviewsError, intl } = props;

  return (
    <div className={css.sectionReviews}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.reviewsTitle" values={{ count: reviews.length }} />
        <span className={css.totalReviews}>
          <IconStarFilled /> {appendAverageReviews(reviews)}
          {'  '}—{'  '}
          {reviews?.length} reviews
        </span>
      </Heading>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}
      {/* <div className={css.givenRatingSec}>
        <div className={css.ratingBlock}>
          <span>Cleanliness</span>
          <div className={css.ratingProgress}>
            <progress id="file" value="100" max="100"></progress>
            <span>5.0</span>
          </div>
        </div>{' '}
        <div className={css.ratingBlock}>
          <span>Accuracy</span>
          <div className={css.ratingProgress}>
            <progress id="file" value="90" max="100"></progress>
            <span>4.9</span>
          </div>
        </div>{' '}
        <div className={css.ratingBlock}>
          <span>Communication</span>
          <div className={css.ratingProgress}>
            <progress id="file" value="80" max="100"></progress>
            <span>4.8</span>
          </div>
        </div>{' '}
        <div className={css.ratingBlock}>
          <span>Location</span>
          <div className={css.ratingProgress}>
            <progress id="file" value="79" max="100"></progress>
            <span>4.6</span>
          </div>
        </div>
        <div className={css.ratingBlock}>
          <span>Check-in</span>
          <div className={css.ratingProgress}>
            <progress id="file" value="70" max="100"></progress>
            <span>4.5</span>
          </div>
        </div>
        <div className={css.ratingBlock}>
          <span>Value</span>
          <div className={css.ratingProgress}>
            <progress id="file" value="60" max="100"></progress>
            <span>4.0</span>
          </div>
        </div>
      </div> */}
      <div className={css.topReviews}>
        {isArrayLength(reviews)
          ? reviews.map(r => {
              const date = r?.attributes?.createdAt;
              const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

              return (
                <div className={css.reviewBlock}>
                  <div className={css.reviewerSec}>
                    <div className={css.userImg}>
                      <Avatar className={css.avatar} user={r?.author} disableProfileLink />
                    </div>
                    <div className={css.userInfo}>
                      <h2>
                        {' '}
                        <UserDisplayName user={r?.author} intl={intl} />
                      </h2>
                      <p> {dateString}</p>
                    </div>
                  </div>
                  <div className={css.reviewDescription}>{r?.attributes?.content}</div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default SectionReviews;
