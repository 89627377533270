import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, Icons, Modal, UserCard } from '../../components';
import InquiryForm from './InquiryForm/InquiryForm';

import css from './ListingPage.module.css';
import IconStarFilled from '../../components/IconStarFilled/IconStarFilled';
import IconEmail from '../../components/IconEmail/IconEmail';

const SectionAuthorMaybe = props => {
  const {
    title,
    listing,
    authorDisplayName,
    onContactUser,
    isInquiryModalOpen,
    onCloseInquiryModal,
    sendInquiryError,
    sendInquiryInProgress,
    onSubmitInquiry,
    currentUser,
    onManageDisableScrolling,
    isListingPageAuthorCard,
    reviews,
  } = props;

  if (!listing.author) {
    return null;
  }

  return (
    <div id="author" className={css.sectionAuthor}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.aboutProviderTitle" />
      </Heading>
      <div className={css.authorDetails}>
        <UserCard
          isListingPageAuthorCard={isListingPageAuthorCard}
          user={listing?.author}
          currentUser={currentUser}
          onContactUser={onContactUser}
          showContact={false}
        />
        {/* <div className={css.contactBlock}>
          <button className={css.contactbtn} onClick={() => onContactUser(listing?.author)}>
            Contact host
          </button>
        </div> */}
      </div>
      <div className={css.moreInfoHost}>
        <div className={css.review}>
          <span>
            <Icons name="reviewIcon" /> {reviews?.length} Reviews
          </span>
          <span>
            <Icons name="identityVerifiedIcon" /> Identity verified
          </span>
        </div>
        {/* <p>
          As a mother of 4, living in Solana Beach for 25+ years, I love to help families enjoy our
          beautiful area. Vacations are special and we do our very best to make them affordable,
          enjoy…
        </p>
        <div className={css.viewAllAmenities}>View more</div> */}
      </div>
      <Modal
        id="ListingPage.inquiry"
        contentClassName={css.inquiryModalContent}
        isOpen={isInquiryModalOpen}
        onClose={onCloseInquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <InquiryForm
          className={css.inquiryForm}
          submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendInquiryError={sendInquiryError}
          onSubmit={onSubmitInquiry}
          inProgress={sendInquiryInProgress}
        />
      </Modal>
    </div>
  );
};

export default SectionAuthorMaybe;
